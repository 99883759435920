/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
 

const awsmobile = {
  "aws_project_region": "ap-southeast-2",
  "aws_cloud_logic_custom": [
      {
          "name": "AdminQueries",
          "endpoint": "https://btgmqxgycl.execute-api.ap-southeast-2.amazonaws.com/cpp",
          "region": "ap-southeast-2"
      }
  ],
  "aws_appsync_graphqlEndpoint": "https://qwzaejprcnevjiljsrhsymizum.appsync-api.ap-southeast-2.amazonaws.com/graphql",
  "aws_appsync_region": "ap-southeast-2",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
  "aws_cognito_identity_pool_id": "ap-southeast-2:4ec5fee2-5fc7-4348-afa4-14c766872a50",
  "aws_cognito_region": "ap-southeast-2",
  "aws_user_pools_id": "ap-southeast-2_JRbysrTKm",
  "aws_user_pools_web_client_id": "keak6jsorn2leu8bn8irk62n8",
  "oauth": {},
  "aws_cognito_username_attributes": [
      "EMAIL"
  ],
  "aws_cognito_social_providers": [],
  "aws_cognito_signup_attributes": [
      "EMAIL",
      "FAMILY_NAME",
      "GIVEN_NAME",
      "PHONE_NUMBER"
  ],
  "aws_cognito_mfa_configuration": "OFF",
  "aws_cognito_mfa_types": [
      "SMS"
  ],
  "aws_cognito_password_protection_settings": {
      "passwordPolicyMinLength": 8,
      "passwordPolicyCharacters": []
  },
  "aws_cognito_verification_mechanisms": [
      "EMAIL"
  ],
  "aws_user_files_s3_bucket": "intellitrackstorage92112-cpp",
  "aws_user_files_s3_bucket_region": "ap-southeast-2"
};


export default awsmobile;